import React, { useState, useEffect, Fragment } from "react";
import { useForm, FormProvider } from "react-hook-form";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";

import Dialog from "@ui-kit/Dialog";
import useStyles from "./styles";
import { ROUTES } from "@constants";
import { EditContactInfo, FormFields, processServerErrors } from "./view";
import { useDeepDivePanelStore, usePrevious } from "@hooks";
import { ReactGAEventWait } from "@helpers/ga";
import { Models } from "@services/api";
import Button from "@ui-kit/Button";
import { EditFacilitySuccessStep } from "@components/EditFacilitySuccessStep";
import { useUserStore } from "@store/UserStore";
import { goToFormError } from "@helpers/goToFormError";
import { useMapStore } from "@store/MapStore";

const EditFacilityForm: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ user }] = useUserStore();
  const [{ facility }] = useDeepDivePanelStore();
  const [, { saveFacility }] = useMapStore();
  const prevUser = usePrevious<Models.User | null>(user);

  const [facilityView, setFacilityView] = useState(cloneDeep(facility));
  const methods = useForm<FormFields>();
  const { handleSubmit, formState, trigger, setError } = methods;

  const [editState, setEditState] = useState(() => ({
    editDesignations: false,
    editDistrict: false,
    editEmail: Boolean(qs.parse(search).editEmail),
    editLanguages: false,
    editName: false,
    editOperatingHours: Boolean(qs.parse(search).editOperatingHours),
    editPhone: false,
    editStatus: false,
  }));
  const [showSuccessfullView, setShowSuccessfullView] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    clearSearchQuery();
  }, []);

  useEffect(() => {
    if (!prevUser && user) {
      void saveChanges();
    }
  }, [user]);

  function clearSearchQuery() {
    const queryParams = {
      editEmail: undefined,
      editOperatingHours: undefined,
    };

    history.replace({
      search: qs.stringify({
        ...qs.parse(search),
        ...queryParams,
      }),
    });
  }

  function checkAuthorization() {
    if (!user) {
      history.replace({
        pathname: ROUTES.SIGNUP_FORM,
        search: qs.stringify({
          joinedFrom: Models.UserJoinedFrom.EditFacilityProfile,
          addressCountry: facility.address.country,
        }),
      });
    } else {
      void saveChanges();
    }
  }

  const saveChanges = handleSubmit(async () => {
    try {
      await saveFacility(facilityView);
      ReactGAEventWait({
        action: "SubmitSuccess",
        category: "FacilityContactInfoEdit",
      });
      setShowSuccessfullView(true);
    } catch (error) {
      const errors = error?.response?.data?.errors;

      if (!errors || !processServerErrors(errors, setError)) {
        throw error;
      }
    }
  });

  const submit = () => {
    void trigger().then((valid) => {
      if (valid) {
        checkAuthorization();
      } else {
        document.querySelectorAll(".form-error").forEach((e) => {
          const parent = e.closest('[data-test="editable-item-block"]');
          const name = parent?.getAttribute("data-name");

          if (name) {
            setEditState((prev) => ({
              ...prev,
              [name]: true,
            }));
          }
        });
        goToFormError();
      }
    });
  };

  const handleClose = () => {
    history.replace({
      pathname: `/map/${facility.id}/edit`,
      search,
    });
  };

  return (
    <Dialog
      open
      protectQuit={!showSuccessfullView}
      onClose={handleClose}
      tier={facility.subscriptionTier}
      data-test="edit-facility-info-form-modal"
      title="Contact information"
    >
      <div className={classes.root}>
        {showSuccessfullView ? (
          <EditFacilitySuccessStep onDone={handleClose} />
        ) : (
          <Fragment>
            <FormProvider {...methods}>
              <EditContactInfo
                editMode
                facilityView={facilityView}
                setFacilityView={setFacilityView}
                state={editState}
                onStateChange={setEditState}
              />
            </FormProvider>

            <Button
              color="primary"
              size="large"
              variant="contained"
              className={classes.saveButton}
              fullWidth
              data-test="submit-btn"
              onClick={submit}
              loading={formState.isSubmitting}
            >
              Submit
            </Button>
          </Fragment>
        )}
      </div>
    </Dialog>
  );
};

export default EditFacilityForm;
